/* */
import React, { useState, useEffect } from "react";

/* */
import { useNavigate } from "react-router-dom";
import axios from "axios";
import API_BASE_URL from "../utils/apiConfig";
import { MdDeleteOutline } from "react-icons/md";
import { FaSpinner } from "react-icons/fa";

/* */
import blueKbcLogo from '../../../assets/images/branding/blue-kbc-logo.png'; 
import ToasterChecklistCreated from "../../toasterscomponents/ToasterChecklistCreated.js";
import { TiTick } from "react-icons/ti";
import { MdOutlineCancel } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import '../../../assets/css/SHEPack.css';

const CreateSHEPack = ({ she_pack_name, checklistUniqueIdentifier }) => {
    //
    const [shePackName, setShePackName] = useState("");


    const [shePackFolder, setShePackFolder] = useState([]);
    //
    const [folders, setFolders] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState('');
    const [editingCategory, setEditingCategory] = useState(null);
    const [editedCategoryName, setEditedCategoryName] = useState("");
    const [categories, setCategories] = useState([]);
    const [newCategory, setNewCategory] = useState("");
    const [categoryError, setCategoryError] = useState("");
    const [questionBankData, setQuestionBankData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [customColumns, setCustomColumns] = useState([]);
    const [newColumnName, setNewColumnName] = useState("");
    const [questionTypes] = useState(['Date', 'Drop Down', 'File', 'Free Text', 'Time', 'Short Text', 'Radio']);
    const [visibleOptions, setVisibleOptions] = useState(3);
    const [submitStatus, setSubmitStatus] = useState('idle');
    const [errors, setErrors] = useState([]);
    const [showSuccess, setShowSuccess] = useState(false);

    const [shePacktotalScore, setShePackTotalScore] = useState(0);


    const [minimizeSHEPackDetails, setMinimizeSHEPackDetails] = useState(false);
    const [minimizeSHEPackCategories, setMinimizeSHEPackCategories] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        fetchQuestionBankData();
        fetchFolders();
    }, []);

   
    useEffect(() => {
        calculateTotalScore();
    }, [questionBankData]);

    const calculateTotalScore = () => {
        const newShePackTotalScore = questionBankData.reduce((total, row) => {
            const score = row.score;
            if (score === 'Not Applicable') return total;
            return total + (parseInt(score) || 0);
        }, 0);
        setShePackTotalScore(newShePackTotalScore);
    };

    const fetchQuestionBankData = async () => {
        try {
            const { data } = await axios.get(`${API_BASE_URL}/columns/Question_Bank`);
            setColumns(data);
        } catch (error) {
            console.error('Error fetching Question Bank data:', error);
        }
    };

    const fetchFolders = async () => {
        try {
            const response = await fetch('/api/she-pack/view-folders');
            const data = await response.json();
            if (Array.isArray(data)) {
                setFolders(data);
            } else {
                console.error('Invalid data format for folders:', data);
            }
        } catch (error) {
            console.error('Error fetching folders:', error);
        }
    };

    const handleEditCategory = (category) => {
        setEditingCategory(category);
        setEditedCategoryName(category);
    };

    const handleSaveEditedCategory = () => {

        if (editedCategoryName.trim() === "") {
            setCategoryError("Category name cannot be empty");
            return;
        }

        if (categories.includes(editedCategoryName) && editedCategoryName !== editingCategory) {
            setCategoryError("This category name already exists");
            return;
        }

        setCategories(prevCategories => prevCategories.map(cat => cat === editingCategory ? editedCategoryName : cat));
        setQuestionBankData(prevData => prevData.map(row => ({
            ...row,
            category: row.category === editingCategory ? editedCategoryName : row.category
        })));

        setEditingCategory(null);
        setEditedCategoryName("");
        setCategoryError("");

    };

    const handleCancelEdit = () => {

        setEditingCategory(null);
        setEditedCategoryName("");
        setCategoryError("");

    };

    const handleNavigateBack = () => {

        navigate('/SHEPACKmanagement');

    };

    const handleInputChange = (rowIndex, columnName, value) => {
        //
        const updatedData = [...questionBankData];
        //
        if (columnName === 'question_type') {
            if (value !== 'Radio' && value !== 'Drop Down') {
                for (let i = 1; i <= 15; i++) {
                    updatedData[rowIndex][`option_${i}`] = '';
                }
            }
            if (value !== 'File') {
                updatedData[rowIndex]['type_file_upload'] = '';
            }
        }

        updatedData[rowIndex] = { ...updatedData[rowIndex], [columnName]: value };
        setQuestionBankData(updatedData);
    };

    const generateUniqueId = (prefix) => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const randomLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26));
        const randomNumber = Math.floor(Math.random() * 10);
        return `${prefix}${year}${month}${day}${hours}${minutes}${randomLetter}${randomNumber}`;
    };

    const handleAddRow = () => {
        const newRow = visibleColumns.reduce((acc, column) => {
            if (column.name === 'question_id') {
                acc[column.name] = generateUniqueId('QID');
            } else if (column.name === 'checklist_id') {
                acc[column.name] = checklistUniqueIdentifier;
            } else if (column.name === 'checklist_name') {
                acc[column.name] = she_pack_name;
            } else if (column.name === 'question_type') {
                acc[column.name] = 'Radio';
            } else {
                acc[column.name] = '';
            }
            return acc;
        }, {});
        setQuestionBankData([...questionBankData, newRow]);
    };

    const handleAddColumn = () => {
        if (newColumnName && !customColumns.includes(newColumnName)) {
            setCustomColumns(prevColumns => [...prevColumns, newColumnName]);
            setNewColumnName("");
            setQuestionBankData(prevData => prevData.map(row => ({
                ...row,
                [newColumnName]: ''
            })));
        }
    };

    const handleSubmit = async () => {
        setSubmitStatus('loading');
        setErrors([]);
        let newErrors = [];
        if (!shePackName.trim()) {
            newErrors.push("Empty Field - SHE Pack Document name cannot be empty.");
        }
        if (questionBankData.length === 0) {
            newErrors.push("No Questions - Add at least one question row to the checklist.");
        }
        const hasMandatoryQuestion = questionBankData.some(row => row.is_mandatory === 'Yes');
        if (!hasMandatoryQuestion) {
            newErrors.push("Mandatory Questions - At least one question must be mandatory.");
        }
        if (!selectedFolder) {
            newErrors.push("Empty Field - Please select a folder for the SHE Pack.");
        }
        if (newErrors.length > 0 || !validateData()) {
            setErrors(prevErrors => [...prevErrors, ...newErrors]);
            window.scrollTo(0, 0);
            setSubmitStatus('idle');
            return;
        }
        try {
            const shePackFiles = questionBankData.map(row => ({
                question_id: row.question_id || generateUniqueId('QID'),
                document_id: row.document_id || generateUniqueId('DOC'),
                she_pack_folder_name: selectedFolder,
                question_name: row.question_name,
                question_type: row.question_type,
                question_score: row.score,
                is_mandatory: row.is_mandatory,
                expires: row.expires,
                category: row.category,
                option_1: row.option_1,
                option_2: row.option_2,
                option_3: row.option_3,
                option_4: row.option_4,
                option_5: row.option_5,
                option_6: row.option_6,
                option_7: row.option_7,
                option_8: row.option_8,
                option_9: row.option_9,
                option_10: row.option_10,
                option_11: row.option_11,
                option_12: row.option_12,
                option_13: row.option_13,
                option_14: row.option_14,
                option_15: row.option_15,
                type_file_upload: row.type_file_upload,
                type_date: row.type_date,
                type_free_text: row.type_free_text,
                type_drop_down: row.type_drop_down
            }));

            const response = await axios.post(`${API_BASE_URL}/api/she-pack/create-she-pack-document`, {
                shePackName,
                shePackFiles,
                shePackFolder: selectedFolder,
                shePacktotalScore
            });

            

            console.log('SHE Pack created and data submitted successfully:', response.data);
            setShowSuccess(true);
            setSubmitStatus('success');
            setTimeout(() => {
                setShowSuccess(false);
                navigate('/SHEPACKmanagement');
            }, 2000);

        } catch (error) {

            console.error('Error submitting data:', error);
            if (error.response && error.response.status === 409) {
                setErrors(prevErrors => [...prevErrors, 'Error - Duplicate Checklist Name. There\'s already a checklist with the existing name.']);
            } else {
                setErrors(prevErrors => [...prevErrors, `Error submitting data: ${error.message}`]);
            }
            setSubmitStatus('error');
        }
    };

    const handleAddCategory = () => {
        if (newCategory.trim() === "") {
            setCategoryError("Empty Field - To save category, please enter a category name.");
            return;
        }

        if (!categories.includes(newCategory)) {
            setCategories([...categories, newCategory]);
            setNewCategory("");
            setCategoryError("");
        } else {
            setCategoryError("Duplicate Entry - This category already exists.");
        }
    };

    const handleClear = () => {
        setShePackName("");
        setQuestionBankData([]);
        setCategories([]);
        setNewCategory("");
        setCategoryError("");
        setErrors([]);
    };

    const handleRemoveRow = (rowIndex) => {
        const updatedData = questionBankData.filter((_, index) => index !== rowIndex);
        setQuestionBankData(updatedData);
    };

    const isCustomColumn = (columnName) => customColumns.includes(columnName);

    const visibleColumns = [
        ...columns.filter(column => !['checklist_id', 'checklist_name', 'question_id'].includes(column.name)),
        ...customColumns.map(name => ({ name }))
    ];

    const validateData = () => {
        const newErrors = [];
        questionBankData.forEach((row, index) => {
            if (!row.question_name || row.question_name.trim() === '') {
                newErrors.push("Error - Question Name: Question name cannot be empty.");
            }
            if (row.question_type === 'Radio' || row.question_type === 'Drop Down') {
                const options = [row.option_1, row.option_2, row.option_3, row.option_4].filter(Boolean);
                if (options.length === 0) {
                    newErrors.push(`Error - ${row.question_type} options: ${row.question_type} question must have at least one option.`);
                }
            }
        });
        setErrors(newErrors);
        return newErrors.length === 0;
    };

    const handleAddOption = () => {
        if (visibleOptions < 15) {
            setVisibleOptions(prevVisible => prevVisible + 1);
        } else {
            setCategoryError("Error: Maximum of 15 options allowed.");
        }
    };

    const renderOptionInputs = (row, rowIndex) => {
        return Array.from({ length: visibleOptions }, (_, i) => {
            const optionName = `option_${i + 1}`;
            return (
                <td key={optionName}>
                    <input
                        type="text"
                        value={row[optionName] || ''}
                        onChange={(e) => handleInputChange(rowIndex, optionName, e.target.value)}
                        disabled={row['question_type'] !== 'Radio' && row['question_type'] !== 'Drop Down'}
                        placeholder={row['question_type'] !== 'Radio' && row['question_type'] !== 'Drop Down' ? 'Options not applicable' : `Enter option ${i + 1}`}
                    />
                </td>
            );
        });
    };

    const visibleColumnNames = [
        'Remove',
        'question_type',
        'question_name',
        'question_score',
        'category',
        'is_mandatory',
        'expires',
        ...Array.from({ length: visibleOptions }, (_, i) => `option_${i + 1}`),
    ];

    return (
        <div className="add-question-custom-field-main-component-container">
            <div className="create-she-pack-main-component-container">
                <div className="create-she-pack-component-container">
                    <div className="create-she-pack-component-container-container">
                        <div className="create-she-pack-header-container">
                            <img src={blueKbcLogo} alt="white-kbc-logo" className="add-quesiton-side-menu-logo" />
                            <button className="navigate-back-button" onClick={handleNavigateBack}>Back</button>
                        </div>
                        <div className="create-she-pack-header-step-container">
                            <h2>Create SHE Pack Document</h2>
                            <div className="create-she-pack-total-score-container">
                                <p className="category-secondary-color">Document Current Score</p>
                                <p className="create-she-pack-total-score-container-paragraph">{shePacktotalScore}</p>
                            </div>
                        </div>
                        <div className="create-she-pack-section-A-main-container">
                            <div className="create-she-pack-section-A-container">
                                <h3>Section A - Information</h3>
                                <button className="confirm-button" onClick={() => setMinimizeSHEPackDetails(!minimizeSHEPackDetails)}>Maximize</button>
                            </div>
                        </div>
                        {minimizeSHEPackDetails && (
                            <div className="create-she-pack-main-container">
                                <div className="create-she-pack-category-details-type-main-container">
                                    <div>
                                        <p className="category-secondary-color">SHE Pack Details</p>
                                        <p>Please fill in the SHE Pack details below</p>
                                    </div>
                                    <div className="create-she-pack-details-main-container">
                                        <div>
                                            <h4>Enter SHE Pack Name</h4>
                                            <input
                                                className="create-questions-form-name-input"
                                                placeholder="Enter SHE Pack Name"
                                                value={shePackName}
                                                onChange={(e) => setShePackName(e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <h4>Choose Folder:</h4>
                                            <select value={selectedFolder} onChange={e => setSelectedFolder(e.target.value)}>
                                                <option value=''>--- Choose Folder ---</option>
                                                {folders.map(folder => (
                                                    <option key={folder.id} value={folder.folder_name}>
                                                        {folder.folder_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="create-she-pack-section-A-main-container">
                            <div className="create-she-pack-section-A-container">
                                <h3>Section B - Categories</h3>
                                <button className="confirm-button" onClick={() => setMinimizeSHEPackCategories(!minimizeSHEPackCategories)}>Maximize</button>
                            </div>
                        </div>
                        {minimizeSHEPackCategories && (
                            <div className="create-she-pack-category-main-right-category-container">
                                <div className="create-she-pack-category-main-main-right-category-container">
                                    <div className="add-question-view-checklist-category-question-type-main-right-category-container">
                                        <p className="category-secondary-color">Create Question Category</p>
                                        <p>To create a category, enter the category name in the input field and click the "Save Category" button to save it.<br/><br/> Saved categories will appear in the saved categories box</p>
                                        <input
                                            className="create-questions-form-name-input"
                                            placeholder="Enter Category name"
                                            value={newCategory}
                                            onChange={(e) => {
                                                setNewCategory(e.target.value);
                                                setCategoryError("");
                                            }}
                                        />
                                        <button type="button" onClick={handleAddCategory} className="confirm-button">Save Category</button>
                                    </div>
                                    <div className="add-question-view-checklist-category-question-type-main-right-category-container">
                                        <h4>Saved Categories</h4>
                                        <p>To edit a category, click on the pen icon. <br/><br />Please remember to save your changes by clicking the green tick.</p>
                                        {categories.length === 0 ? (
                                            <p style={{fontStyle : 'italic'}}>No categories have been added yet.</p>
                                        ) : (
                                            categories.map((category, index) => (
                                                <div key={index} className="add-question-view-checklist-category-list-item-main-container">
                                                    {editingCategory === category ? (
                                                        <div className="add-question-view-checklist-category-list-item-container">
                                                            <input
                                                                value={editedCategoryName}
                                                                onChange={(e) => setEditedCategoryName(e.target.value)}
                                                                className="add-question-view-checklist-category-list-input"
                                                            />
                                                            <div className="add-question-view-checklist-icon-container">
                                                                <TiTick className="add-question-view-checklist-tick-icon-container" onClick={handleSaveEditedCategory} />
                                                                <MdOutlineCancel className="add-question-view-checklist-cancel-icon-container" onClick={handleCancelEdit}/>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="add-question-view-checklist-category-list-item-container">
                                                            <span>{category}</span>
                                                            <CiEdit className="add-question-view-checklist-category-list-item-edit-icon" onClick={() => handleEditCategory(category)} />
                                                        </div>
                                                    )}
                                                </div>
                                            ))
                                        )}
                                        {categoryError && <p className="error-text">{categoryError}</p>}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="create-she-pack-table-container">
                            <div className="create-she-pack-table-table-container">
                                <table className="create-she-pack-view-table">

                                    <colgroup>

                                        <col style={{width: "60px"}}/>
                                        <col style={{width: "150px"}}/>
                                        <col style={{width: "300px"}}/>
                                        <col style={{width: "150px"}}/>
                                        <col style={{width: "150px"}}/>
                                        <col style={{width: "150px"}}/>
                                        <col style={{width: "150px"}}/>

                                        {Array.from({ length: visibleOptions }, (_, i) => (
                                            <col key={i} style={{width: "150px"}}/>
                                        ))}

                                    </colgroup>

                                    <thead>
                                        <tr>
                                            {visibleColumnNames.map((columnName, index) => (
                                                <th key={index} className="table-header">
                                                    {columnName === 'Remove' ? '' : columnName}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody className="view-table-body">
                                        {questionBankData.length === 0 ? (
                                            <tr>
                                                <td colSpan={visibleColumnNames.length}>No Questions added to checklist, click 'add row' to add a question.</td>
                                            </tr>
                                        ) : (
                                            questionBankData.map((row, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    <td>
                                                        <button onClick={() => handleRemoveRow(rowIndex)} className="remove-row-button">
                                                            <MdDeleteOutline className='delete-checklist-submition-icon' />
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <select
                                                            value={row['question_type'] || 'Radio'}
                                                            onChange={(e) => handleInputChange(rowIndex, 'question_type', e.target.value)}
                                                        >
                                                            {questionTypes.map((type, index) => (
                                                                <option key={index} value={type}>{type}</option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            value={row['question_name'] || ''}
                                                            onChange={(e) => handleInputChange(rowIndex, 'question_name', e.target.value)}
                                                            placeholder="Enter question name"
                                                            className="create-questions-form-question-input-column"
                                                        />
                                                    </td>

                                                    <td>
                                                        
                                                        <input
                                                            type="number"
                                                            value={row['score'] || ''}
                                                            onChange={(e) => handleInputChange(rowIndex, 'score', e.target.value)}
                                                            placeholder="Enter question score"
                                                            className="create-questions-form-question-number-input-column"
                                                        />
                                                        
                                                    </td>

                                                    <td>
                                                        <select
                                                            value={row['category'] || ''}
                                                            onChange={(e) => handleInputChange(rowIndex, 'category', e.target.value)}
                                                        >
                                                            <option value="">Select a category</option>
                                                            {categories.map((category, index) => (
                                                                <option key={index} value={category}>{category}</option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select
                                                            value={row['is_mandatory'] || 'No'}
                                                            onChange={(e) => handleInputChange(rowIndex, 'is_mandatory', e.target.value)}
                                                        >
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select
                                                            value={row['expires'] || 'No'}
                                                            onChange={(e) => handleInputChange(rowIndex, 'expires', e.target.value)}
                                                        >
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </select>
                                                    </td>
                                                    {renderOptionInputs(row, rowIndex)}
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                                {errors.length > 0 && (
                                    <div className="error-text">
                                        <ul>
                                            {errors.map((error, index) => (
                                                <li key={index}>{error}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                <div className="create-she-pack-add-row-add-option-container">
                                    <button onClick={handleAddRow} className="confirm-button-response">Add Question</button>
                                    <button onClick={handleAddOption} className="confirm-button-response" disabled={visibleOptions >= 15}>
                                        Add Option
                                    </button>
                                </div>
                                {categoryError && <div className="error-text">{categoryError}</div>}
                                <div style={{ textAlign: 'center' }}>
                                    <p>By clicking 'Create SHE Pack' SHE pack will be created.</p>
                                </div>
                                <div className="create-she-pack-submit-clear-button-container">
                                    <button 
                                        onClick={handleSubmit} 
                                        className="confirm-button" 
                                        disabled={submitStatus === 'loading'}
                                    >
                                        {submitStatus === 'loading' ? (
                                            <>
                                                <FaSpinner className="fa-spin" />
                                                <span className="submitting-questions-text"> Creating SHE Pack...</span>
                                            </>
                                        ) : (
                                            'Create SHE Pack'
                                        )}
                                    </button>
                                    <button onClick={handleClear} className="cancel-button">Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*{
                <ToasterChecklistCreated
                heading='SHE Pack Created'
                bodyParagraph='One moment. Redirecting you to the next page'
            /   >
            }
             */}

             {/*Toaster */}

             {showSuccess && 

                <ToasterChecklistCreated
                    heading='SHE Pack Created'
                    bodyParagraph='One moment. Redirecting you to the next page'
                />
             }

            

            
        </div>
    );
};

export default CreateSHEPack;
