// ------------------- React Components --------------------
import React, { useState, useEffect, lazy } from 'react';
import { Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// -------------- Application Components ----------------------------
import LoadingComponents from './components/loadingComponents/LoadingComponents';
import AddDepartmentComponent from './components/manageuserscomponents/addDepartmentComponent/AddDepartmnetComponent';
import CreateGenericFolder from './components/checklistmanagementcomponents/createSHEPackcomponents/CreateGenericFolder';
import CreateShePack from './components/checklistmanagementcomponents/createSHEPackcomponents/CreateSHEPackComponent';
import ViewSHEPack from './components/checklistmanagementcomponents/viewSHEPacksComponents/ViewSHEPacks';
import ShePackFillIn from './components/checklistmanagementcomponents/fillinchecklistcomponents/ShePackFillIn';

// Lazy load components
const Header = lazy(() => import('./components/Header'));
const UserInfoHeader = lazy(() => import('./components/UserInfoHeader'));
const Login = lazy(() => import('./components/Login'));
const Dashboard = lazy(() => import('./components/dashboardcomponents/Dashboard'));
const ChecklistManagement = lazy(() => import('./components/checklistmanagementcomponents/Checklist'));

const ViewChecklist = lazy(() => import('./components/checklistmanagementcomponents/viewchecklistcomponents/ViewCheckList'));
const CreateSHEPack = lazy(() => import('./components/checklistmanagementcomponents/createSHEPackcomponents/CreateSHEPackComponent'));
const ChecklistFillIn = lazy(() => import('./components/checklistmanagementcomponents/fillinchecklistcomponents/ChecklistFillIn'));
const ManageUsers = lazy(() => import('./components/manageuserscomponents/ManagerUsers'));
const CreateCompanyComponent = lazy(() => import('./components/manageuserscomponents/createcompanycomponents/CreateCompanyComponent'));
const CreateUserComponent = lazy(() => import('./components/manageuserscomponents/createusercomponents/CreateUserComponent'));
const ViewUsersComponent = lazy(() => import('./components/manageuserscomponents/viewuserscomponents/Viewuserscomponent'));
const AssignUserComponent = lazy(() => import('./components/manageuserscomponents/assignuserscomponents/AssignUserComponent'));
const ProfileComponents = lazy(() => import('./components/Profilecomponents/ProfileComponents'));
const HelpComponents = lazy(() => import('./components/helpcomponents/HelpComponents'));

const RoleBasedRoute = lazy(() => import('./components/utils/Authentication/RoleBasedRoute'));

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    const authStatus = JSON.parse(sessionStorage.getItem('isAuthenticated'));
    const user = JSON.parse(sessionStorage.getItem('user'));
    if (authStatus && user && user.userRole) {
      setIsAuthenticated(authStatus);
      setUserRole(user.userRole);
    } else {
      if (window.location.pathname !== '/') {
        sessionStorage.removeItem('isAuthenticated');
        sessionStorage.removeItem('user');
        setIsAuthenticated(false);
        setUserRole('');
        window.location.replace('/');
      }
    }
  }, []);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);

  const handleAuthentication = (status, user) => {
    setIsAuthenticated(status);
    if (status && user && user.userRole) {
      setUserRole(user.userRole);
      sessionStorage.setItem('isAuthenticated', JSON.stringify(status));
      sessionStorage.setItem('user', JSON.stringify(user));
    } else {
      sessionStorage.removeItem('isAuthenticated');
      sessionStorage.removeItem('user');
      setUserRole('');
      setIsAuthenticated(false);
    }
  };

  return (
    <BrowserRouter className='main-div'>

      {/*
      <Helmet>
        <meta http-equiv="Content-Security-Policy" content="default-src 'self'; script-src 'self'; style-src 'self'; img-src 'self';" />
        <meta http-equiv="X-Content-Type-Options" content="nosniff" />
        <meta http-equiv="X-Frame-Options" content="DENY" />
        <meta http-equiv="X-XSS-Protection" content="1; mode=block" />
      </Helmet>
      */}
      
      <Suspense fallback={<LoadingComponents />}>
        <div className="application-component-container">
          <div className="application-container">
            {isAuthenticated ? (
              <div className="application-layout">
                <div className={`app-container ${isCollapsed ? 'header-collapsed' : ''}`}>
                  <Header
                    className='header-container '
                    isAuthenticated={isAuthenticated}
                    onAuthentication={handleAuthentication}
                    isCollapsed={isCollapsed}
                    toggleCollapse={toggleCollapse}
                  />
                </div>
                <div className="main-content-container">
                  <div className="app-user-info-header-container">
                    <UserInfoHeader className='user-info-header-container' isAuthenticated={isAuthenticated} onAuthentication={handleAuthentication} />
                  </div>
                  <div className="app-routes-main-container">
                    <Routes>
                      <Route
                        path="/dashboard"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin', 'user']}>
                            <Dashboard />
                          </RoleBasedRoute>
                        }
                      />

                      {/* ------------- She Pack Management ----------- */}

                      
                      <Route
                        path="/SHEPACKmanagement"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin', 'user']}>
                            <ChecklistManagement />
                          </RoleBasedRoute>
                        }
                      />

                      {/* She Pack Management - Create She Pack */}
                      <Route
                        path="/SHE-Pack/create-she-pack"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            <CreateGenericFolder />
                          </RoleBasedRoute>
                        }
                      />

                      {/* She Pack Management - Create She Pack Document*/}
                      <Route
                        path="/SHE-Pack/create-she-pack-document"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            <CreateShePack />
                          </RoleBasedRoute>
                        }
                      />

                      {/* She Pack Management - Create She Pack Document*/}
                      <Route
                        path="/SHE-Pack/view-she-pack"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            <ViewSHEPack />
                          </RoleBasedRoute>
                        }
                      />

                      {/* She Pack Management - Create She Pack Document*/}
                      <Route
                        path="/SHE-Pack/she-pack-fill-in"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            <ShePackFillIn />
                          </RoleBasedRoute>
                        }
                      />

                      <Route
                        path="/checklistfillin"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin', 'user']}>
                            <ChecklistFillIn />
                          </RoleBasedRoute>
                        }
                      />

                      <Route
                        path="/viewchecklist"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            <ViewChecklist />
                          </RoleBasedRoute>
                        }
                      />

                      {/* ------------------- People Management -----------------------  */}

                      <Route
                        path="/managerusers"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            <ManageUsers />
                          </RoleBasedRoute>
                        }
                      />

                      <Route
                        path='/createcompany'
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin']}>
                            <CreateCompanyComponent />
                          </RoleBasedRoute>
                        }
                      />

                      <Route
                        path='/people-managament/add-department'
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin']}>
                            <AddDepartmentComponent />
                          </RoleBasedRoute>
                        }
                      />

                      <Route
                        path='/createuser'
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin']}>
                            <CreateUserComponent />
                          </RoleBasedRoute>
                        }
                      />
                      <Route
                        path='/viewusers'
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            <ViewUsersComponent />
                          </RoleBasedRoute>
                        }
                      />
                      <Route
                        path='/assignusers'
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            <AssignUserComponent />
                          </RoleBasedRoute>
                        }
                      />
                      <Route
                        path="/profile"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin', 'user']}>
                            <ProfileComponents />
                          </RoleBasedRoute>
                        }
                      />
                      <Route
                        path="/help"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin', 'user']}>
                            <HelpComponents />
                          </RoleBasedRoute>
                        }
                      />
                      <Route path="*" element={<Navigate to="/dashboard" />} />
                    </Routes>
                  </div>
                </div>
              </div>
            ) : (
              <Routes>
                <Route path="/" element={<Login onAuthentication={handleAuthentication} />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            )}
          </div>
        </div>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
