/*----------------------- Manage users - Add Departmennt Component ----------------------- */

/* This component is used for when a user wants needs to add a department to an exisiting company  */

// ---------------- React Components -------------------------
import React from "react";

import { useNavigate } from "react-router-dom";

import { useState } from "react";

import { useEffect } from "react";

import axios from "axios";


// ------------------------- Styles -------------------

// Styles - Css
import '../../../assets/css/ManageUsers.css';


// ----------------------------- Assets -------------------------------

// Assests - Brandng 
import blueKbcLogo from '../../../assets/images/branding/blue-kbc-logo.png';

// Assets - icons 
import { FaRegEdit } from "react-icons/fa"; // Edit Icon 
import { IoIosAddCircleOutline } from "react-icons/io"; // Add Icon 


// ------------------- Component Add Department ------------------------------
const AddDepartmentComponent = () => {

    // ---------------- state Management ---------------

    // state managemnet - companies 
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState("");

    // state management - department 
    const[departments, setDepartments] = useState([])

    const[newDepartmentName, setNewDepartmentName] = useState('')

    const navigate = useNavigate() 

    // --------------------- functions ------------

    //
    const handleNavigateBack = () => {
        navigate('/managerusers');
      };

      // functions & use effect - Companies 
      useEffect(() => {
        const fetchCompanies = async () => {
          try {
            const response = await axios.get('/api/people-management/show-companies');
            setCompanies(response.data.data);
           
          } catch (err) {
            //setError('Failed to fetch companies');
            //setLoading(false);
          }
        };
    
        fetchCompanies();
      }, []);

      // functions & useEffects - departments 
      
  const handleSearchDepartments = async () => {

    // This function is used to search for all the departments that are linked toa user
    if (!selectedCompany) {
      return;
    }

   

    try {
      const response = await axios.get(`/api/people-management/show-departments/${selectedCompany}`);
      setDepartments(response.data.data);
    } catch (err) {
      
      console.error('Error fetching departments:', err);
    } finally {
      
    }
  };

  const handleCreateDepartment = async () => {
    if (!selectedCompany || !newDepartmentName.trim()) {
      
      return;
    }

    

    try {
      const response = await axios.post('/api/people-management/create-department', {
        departmentName: newDepartmentName.trim(),
        companyId: selectedCompany
      });

      // Add the new department to the list
      setDepartments([...departments, response.data.data]);
      
      
      
      // Refresh the departments list
      handleSearchDepartments();

    } catch (err) {
      
      console.error('Error creating department:', err);
    } finally {
     
    }
  };

    

    return (
        <div className="component-container">

            <div className="create-checklist-component-container">

                <div className="create-company-text-container">

                    <div>
                        <h1> Add Department </h1>

                        <p>To add departments to existing companies.</p>
                    </div>

                    <div>
                        <img src={blueKbcLogo} alt="white-kbc-logo" className="side-menu-logo" />
                    </div>
                    
                </div>

                <div className="navigate-back-button-main-container">

                    <div className="navigate-back-button-container">
                        <button className="navigate-back-button" onClick={handleNavigateBack}>Back</button>
                    </div>

                </div>

                <div className="manage-users-add-department-main-body-container">

                    <div className="manage-users-add-department-main-body-section-container">

          
                    
                        <div className="manage-users-add-department-main-body-company-name-add-department-container">
                            <div>
                                <h3 className="secondary-color-heading">Company Name</h3>
                                <p>Choose the company that you'd like to search the departments for</p>
                            </div>

                            <div className="manage-users-add-department-main-body-company-name-input-button-field-container">
                                <div>
                                    <select 
                                        value={selectedCompany}
                                        onChange={(e) => setSelectedCompany(e.target.value)}
                                        className="manage-users-add-department-main-body-company-name-input"
                                    >
                                        <option value="">Select a company</option>
                                        {Array.isArray(companies) && companies.map((company) => (
                                            <option key={company.id} value={company.id}>
                                                {company.companyName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <button className="confirm-button" onClick={handleSearchDepartments}>Search Departments</button>
                                </div>
                            </div>
                        </div>

                        <div className="manage-users-add-department-main-body-company-name-add-department-container">

                            <div>

                                <h3 className="secondary-color-heading">Create Department For :  </h3>

                                <p>To create a department, enter the department name in the input field and click the "Save Department" button to save it </p>

                            </div>

                            <div className="manage-users-add-department-main-body-company-name-input-button-field-container">

                                <div>
                                    <input 
                                        placeholder="Enter new department name"
                                        className="manage-users-add-department-main-body-company-name-input"
                                    />
                                </div>

                                <div>
                                    <button className="confirm-button" onClick={handleCreateDepartment}> Create Department</button>
                                </div>

                            </div>
                            
                           
                        </div>

                    </div>

                    

                    <div className="view-checklist-table-container">

                        <div className="manage-users-add-department-main-body-section-container">

                            <div className="manage-users-add-department-main-body-company-name-add-department-container">

                                <div>
                                    <h4>Departments for selected company : </h4>
                                </div>

                                
                        
                            </div>

                            
                            
                        </div>

                        <div>
                            <div>
                                <   p>Below are all the departments for company </p>
                                </div>
                        </div>

                        

                    <table className="view-checklist-table">
                        <thead>
                            <tr>
                                <th className="table-header">Department ID</th>
                                <th className="table-header">Department Name </th>
                                <th className="table-header">Department Id </th>
                                <th className="table-header">Date Created</th>
                                <th className="table-header">Created By</th>
                                <th className="table-header">Edit / Remove</th>
                            </tr>
                        </thead>

                        <tbody className="view-table-body">
                            {departments.map((dept) => (
                                <tr key={dept.id}>
                                    <td>{dept.id}</td>
                                    <td>{dept.departmentName}</td>
                                    <td>{dept.department_id}</td>
                                    <td>{dept.date_created}</td>
                                    <td></td>
                                    <td>
                                        <FaRegEdit className='delete-checklist-submition-icon' />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                        
                    </div>

                </div>

            
            </div>

    </div>
    )
}

export default AddDepartmentComponent