import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Assets - Branding 
import blueKbcLogo from '../../../assets/images/branding/blue-kbc-logo.png'; 

// Application Components - Toasters
import ToasterChecklistCreated from "../../toasterscomponents/ToasterChecklistCreated.js";

// Styles - Css
import '../../../assets/css/SHEPack.css';

const CreateGenericFolder = () => {

    const [folderName, setFolderName] = useState("");
    const [submitStatus, setSubmitStatus] = useState('idle');
    const [errors, setErrors] = useState([]);
    const [showSuccess, setShowSuccess] = useState(false);

    const navigate = useNavigate();

    const handleNavigateBack = () => {
        navigate('/SHEPACKmanagement');
    };

    const handleSubmit = async () => {

        //m-
        setSubmitStatus('loading');
        setErrors([]);

        if (!folderName.trim()) {
            setErrors(["Folder name is required"]);
            setSubmitStatus('idle');
            return;
        }

        try {
            const response = await axios.post('/api/she-pack/create-she-pack-folder', {
                shePackFolderName: folderName,
                shePackFiles: [] // Add files here if needed
            });

            if (response.status === 200) {

                setShowSuccess(true);

                setTimeout(() => {

                    navigate('/SHE-Pack/create-she-pack-document');
                    
                }, 2000); // Navigate after 2 seconds
            }

        } catch (error) {

            if (error.response) {

                if (error.response.status === 409) {
                    setErrors(["A folder with this name already exists"]);
                } else {
                    setErrors([`Error: ${error.response.data}`]);
                }
            } else {
                setErrors(["An error occurred while creating the folder"]);
            }

        } finally {
            setSubmitStatus('idle');
        }

    };

    return (

        <div className="add-question-custom-field-main-component-container">
            <div className="create-a-folder-main-component-container">
                <div className="create-questions-custom-field-container">
                    <div className="add-question-view-checklist-component-container">
                        {/* Header and navigation */}
                        <div className="folder-add-question-view-checklist-header-container">
                            <div>
                                <img src={blueKbcLogo} alt="white-kbc-logo" className="add-quesiton-side-menu-logo" />
                            </div>
                            <div className="navigate-back-button-container">
                                <button className="navigate-back-button" onClick={handleNavigateBack}>Back</button>
                            </div>
                        </div>
                        
                        {/* Create Folder Form */}
                        <div className="create-questions-custom-header-field-container">
                            <div>
                                <h2>Create SHE Pack</h2>
                            </div>
                            <div>
                                <p>Please enter details to create SHE Pack</p>
                            </div>
                        </div>
                        
                        <div className="create-folder-custom-header-field-container">
                            <div className="create-she-pack-folder-form-element-field-container">

                                <div>
                                    <p className="category-paragraph-secondary-color">SHE Pack Name</p>
                                </div>

                                <div>
                                    <input 
                                        placeholder="Enter SHE Pack name"
                                        value={folderName}
                                        onChange={(e) => setFolderName(e.target.value)}
                                        className="create-questions-form-name-input"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="create-folder-text-button-field-container">
                            <div>
                                <p>By clicking 'Create Folder' button folder will be created</p>
                            </div>
                            <div className="create-folder-button-field-container">
                                <div>
                                    <button 
                                        className="confirm-button" 
                                        onClick={handleSubmit}
                                        disabled={submitStatus === 'loading'}
                                    >
                                        {submitStatus === 'loading' ? 'Creating SHE Pack...' : 'Create SHE Pack'}
                                    </button>
                                </div>
                                <div>
                                    <button className="cancel-button" onClick={handleNavigateBack}>Cancel</button>
                                </div>
                            </div>
                        </div>

                        { errors.length > 0 && (
                            <div className="error-messages">
                                {errors.map((error, index) => (
                                    <p key={index} className="error-message">{error}</p>
                                ))}
                            </div>
                        )}

                    </div>
                </div>
            </div>
             

            {showSuccess &&
                <ToasterChecklistCreated
                    heading='SHE Pack Created'
                    bodyParagraph='One moment. Redirecting you to the next page'
                />
            }

        </div>
    );
};

export default CreateGenericFolder;