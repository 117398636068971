import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import API_BASE_URL from '../utils/apiConfig';
import { fetchDatabaseTables } from '../utils/frontendTables';
import ConfirmdeleteTableToaster from '../../toasterscomponents/checklistToasters/ConfirmdeleteTableToaster';
import '../../../assets/css/Checklists.css';
import { FaRegEye } from 'react-icons/fa';
import { LuUsers } from 'react-icons/lu';
import { MdDeleteOutline } from "react-icons/md";
import { CiImageOn } from "react-icons/ci";
import { LiaSpinnerSolid } from "react-icons/lia";

import { IoDocumentTextOutline } from "react-icons/io5";

import { TfiWrite } from "react-icons/tfi";

const ShePackFillIn = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [shePacks, setShePacks] = useState([]);
  const [selectedShePack, setSelectedShePack] = useState(null);
  const [shePackDocuments, setShePackDocuments] = useState([]); // New state for documents
  const [checklistToDelete, setChecklistToDelete] = useState(null);
  const [showDeleteToaster, setShowDeleteToaster] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  
  const navigate = useNavigate();

  useEffect(() => {
    fetchShePackNames();
  }, []);

  const fetchShePackNames = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/api/she-pack/view-folders`);
      setShePacks(response.data);
    } catch (error) {
      console.error('Error fetching SHE Pack names:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // New function to fetch documents for a specific SHE Pack
  const fetchShePackDocuments = async (folderName) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/api/she-pack/documents/${folderName}`);
      setShePackDocuments(response.data);
      setSelectedShePack(folderName);
    } catch (error) {
      console.error('Error fetching SHE Pack documents:', error);
      setShePackDocuments([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = (folderId, folderName) => {
    fetchShePackDocuments(folderName);
  };

  const handleDeleteClick = (checklistId) => {
    setChecklistToDelete(checklistId);
    setShowDeleteToaster(true);
  };

  const handleConfirmDelete = async () => {
    // Implementation remains the same
  };

  const handleCancelDelete = () => {
    setShowDeleteToaster(false);
  };

  const handleNavigateBack = () => {
    navigate('/checklistmanagement');
  };

  const Pagination = ({ rowsPerPage, totalRows, paginate, currentPage }) => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalRows / rowsPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <nav>
        <ul className='pagination'>
          {pageNumbers.map(number => (
            <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
              <a onClick={() => paginate(number)} href='#!' className='page-link'>
                {number}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    );
  };

  return (
    <div className="component-container">
      <div className="create-checklist-component-container">
        <div className="create-checklist-text-container">
          <div>
            <h1>Fill In SHE Pack</h1>
            <p>To fill in a checklist</p>
          </div>
        </div>

        <div className="navigate-back-button-main-container">
          <div className="navigate-back-button-container">
            <button className="navigate-back-button" onClick={handleNavigateBack}>Back</button>
          </div>
        </div>

        <div className="view-checklist-table-container">

        <dib>
            <h2>Select SHE Pack</h2>
            <p>Select a SHE Pack that you'd like to complete by clicking on the icon with the pen and paper</p>
        </dib>

          {isLoading ? (
            <div className="loading-spinner-container">
              <LiaSpinnerSolid className="lia-spin-solid-icon" />
            </div>
          ) : (
            <div className="view-checklist-table-table-container">
              <table className="view-checklist-table">
                <thead>
                  <tr>
                    <th className="table-header">Fill In</th>
                    <th className="table-header">SHE Pack Name</th>
                    
                    
                   
                  </tr>
                </thead>
                <tbody className="view-table-body">
                  {shePacks.length === 0 ? (
                    <tr>
                      <td colSpan="5">
                        <p>Currently there are no created SHE Packs, click "Create SHE Pack" button.</p>
                        <Link to='/SHE-Pack/create-she-pack' className='link-decoration-remove'>
                          <div className='view-checklist-create-checklist-button-contianer'>
                            <p>Create SHE Pack</p>
                          </div>
                        </Link>
                      </td>
                    </tr>
                  ) : (
                    shePacks.map((shePack, index) => (
                      <tr key={index}>
                        <td onClick={() => handleClick(shePack.folder_id, shePack.folder_name)}>
                          <IoDocumentTextOutline  className='view-checklist-submition-icon' />
                        </td>
                        <td>{shePack.folder_name}</td>
                       
                        
                        
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>

        {selectedShePack && (

          <div className='view-checklist-checklist-data-container'>
            <div className="view-data-checklist-table-container">
              <div>
                
                <h1>Documents</h1>

                <p>Choose the document that you'd like to complete from the the {selectedShePack} SHE pack</p>

                <h3>Selected SHE pack :  <span style={{ color: 'black' }}> {selectedShePack} </span></h3>
                
              </div>

              {isLoading ? (
                <div className="loading-spinner-container">
                  <LiaSpinnerSolid className="lia-spin-solid-icon" />
                </div>
              ) : (
                <div className="view-checklist-table-table-container">
                  <table className="view-checklist-table">
                    <thead>
                      <tr>
                        <th className="table-header">Fill In</th>
                        <th className="table-header">Document Name</th>
                        <th className="table-header">Statuts</th>
                        
                      </tr>
                    </thead>
                    <tbody className="view-table-body">
                      {shePackDocuments.length === 0 ? (
                        <tr>
                            
                          <td colSpan="4">
                            <p>No documents found for this SHE Pack</p>
                            <Link to='/SHE-Pack/create-she-pack-document' className='link-decoration-remove'>
                              <div className='view-checklist-create-checklist-button-contianer'>
                                <p>Create Document</p>
                              </div>
                            </Link>
                          </td>
                        </tr>
                      ) : (
                        shePackDocuments.map((doc, index) => (
                          <tr key={index}>
                            <td >
                                <TfiWrite  className='view-checklist-submition-icon' />
                            </td>
                            <td>{doc.she_pack_document_name}</td>
                            <td>Not Completed 0%</td>
                            
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        )}
      </div>


      {showDeleteToaster && (

        <ConfirmdeleteTableToaster
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />

      )}

    </div>
  );
};

export default ShePackFillIn;